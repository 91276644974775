/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useState } from 'react';
import { useAuth } from 'components/provider/Auth';
import Avatar from 'components/shared/Avatar';
import Button from 'components/shared/Button';
import Modal from 'components/shared/Modal';
import LoginForm from 'components/shared/LoginForm';

const AccountPicker = ({ 
  account = false, 
  isActive,
  setIsActive
}) => {

  const auth = useAuth();
  const [ isLoginFormShowing, setIsLogInFormShowing ] = useState(false);

  return (
    <Modal 
      title="Select an account" 
      isActive={isActive} 
      onCancel={() => setIsActive(false)} 
    >
      { !isLoginFormShowing && (
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: 380,
            position: 'relative',
          }}
        >
          <div>
            <ul
              css={{
                marginTop: 0,
                marginLeft: 0,
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              { auth?.accounts && auth.accounts.map(a => {
                return (
                  <li
                    key={a.data.id}
                    css={{
                      display: 'grid',
                      gridTemplateColumns: '50px 1fr 70px',
                      gridTemplateRows: 'auto',
                      alignItems: 'center',
                      cursor: 'pointer',
                      padding: '20px',
                      backgroundColor: a.isActive ? 'rgba(111, 188, 230, 0.3)' : 'none',
                    }}
                    onClick={() => {
                      setIsActive(false);
                      auth.switchToAccount(a);
                    }}
                  >
                    <Avatar account={a}/>
                    <div>{a?.data?.name}</div>
                    <Button
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        auth.logoutAccount(a);
                        setIsActive(false);
                      }}
                      style={{
                        paddingLeft: 10,
                        paddingRight: 10
                      }}
                    >
                      Logout
                    </Button>
                  </li>
                )
              })}
            </ul>
            <div
              css={{
                padding: '20px 20px 0px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                justifyContent: 'center',
              }}
            >
              <Button type='primary' onClick={() => setIsLogInFormShowing(true)}>
                Add an account
              </Button>
            </div>
          </div>
        </div>
      )}
      { isLoginFormShowing && (
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: 380,
            position: 'relative',
          }}
        >
          <LoginForm onSubmit={() => {
            setIsLogInFormShowing(false);
            setIsActive(false);
          }}/>
          <div
            css={{
              padding: '0px 20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              justifyContent: 'center',
              height: 140,
            }}
          >
            <Button onClick={() => setIsLogInFormShowing(false)}>Cancel</Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default AccountPicker;
